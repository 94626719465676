var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isAuthenticated)?_c('div',{staticClass:"my-4 mx-4"},[_c('h1',[_vm._v("Activités")]),_c('p',[_vm._v("Mes activités")]),(!_vm.sessions.length)?_c('p',[_vm._v("Aucune session disponible")]):_vm._e(),_c('v-list',[_c('v-list-item-group',_vm._l((_vm.sessions),function(session){return _c('v-list-item',{key:session.session_id,staticClass:"blue-grey lighten-5 my-2",attrs:{"id":session.id}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(session.title)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(
              `Début: ${
                session.started_time
                  ? new Date(session.started_time).toLocaleString('fr-FR')
                  : 'Non déterminée'
              }
                 Fin: ${
                   session.ended_time
                     ? new Date(session.ended_time).toLocaleString('fr-FR')
                     : 'Non déterminée'
                 }`
            )}}),(session.status !== 'finished')?_c('v-btn',{staticClass:"my-1",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.redirectToSession(session)}}},[_vm._v(" "+_vm._s(_vm.getUF("start"))+" ")]):_vm._e()],1)],1)}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }