
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { apiUrl } from "@/env";
import store from "@/store";

@Component({ name: "DataExportDialog" })
export default class DataExportDialog extends Vue {
  available_formats = ["json", "csv"];
  export_format = "csv";

  @Prop({ required: true, type: String })
  sequenceCode!: string;

  get fileName(): string {
    let today = new Date();
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return `export_sequence_${this.sequenceCode}_${today
      .toLocaleDateString("fr-FR", options)
      .replaceAll("/", "-")}.${this.export_format}`;
  }
  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  get dataFileUrl(): string {
    return `${apiUrl}/sequences/${this.sequenceCode}/data/?format=${this.export_format}&token=${store.state.token}`;
  }
}
