
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "DynamicScore",
})
export default class DynamicScore extends Vue {
  steps = [
    {
      interval: {
        min: 0,
        max: 16,
      },
      color: "green lighten-5",
    },
    {
      interval: {
        min: 16,
        max: 32,
      },
      color: "green lighten-4",
    },
    {
      interval: {
        min: 32,
        max: 48,
      },
      color: "green lighten-3",
    },
    {
      interval: {
        min: 48,
        max: 64,
      },
      color: "green lighten-2",
    },
    {
      interval: {
        min: 64,
        max: 80,
      },
      color: "green lighten-1",
    },
    {
      interval: {
        min: 80,
        max: 96,
      },
      color: "green darken-2",
    },
    {
      interval: {
        min: 96,
        max: 112,
      },
      color: "green darken-4",
    },
  ];
  max = 112;
  scores = [10, 50, 70, 98, 34];
  groupScore = 68;

  /**
   * Return css style for step
   * @param step
   */
  getStepStyle(step: Record<string, any>): string {
    return `left: ${this.getPercentage(step.interval.min)}%;
          width: ${this.getPercentage(
            step.interval.max - step.interval.min
          )}%;`;
  }

  /**
   * Return proportional percentage from interval
   * @param value
   */
  getPercentage(value: number): number {
    return (value * 100) / this.max;
  }
}
