
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { apiUrl } from "@/env";
import store from "@/store";
import { authHeader, corsHeaders } from "@/repository/deps";

@Component({ name: "MoveAndChatExportDialog" })
export default class MoveAndChatExportDialog extends Vue {
  available_formats = ["json", "csv"];
  export_format = "json";

  @Prop({ required: true, type: String })
  sequenceId!: string;
  @Prop({ required: true, type: String })
  sessionId!: string;

  // /chat/{session_id}

  get fileName(): string {
    let today = new Date();
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return `export_session_${this.sequenceId}_${this.sessionId}_${today
      .toLocaleDateString("fr-FR", options)
      .replaceAll("/", "-")}.${this.export_format}`;
  }
  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  get dataFileUrl(): any {
    let test=`${apiUrl}/sequences/getsessionhisto/${this.sequenceId}/${this.sessionId}/?token=${store.state.token}`;
    return test
  }
}
