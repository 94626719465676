
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  UFliterals,
  UFSessionStateColorEnum,
  UFSessionStateEnum,
} from "@/utils/literals";
import { ISequence } from "@/schemas/ISequence";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UserSessionsList from "@/components/UserSessionsList.vue";
import Score from "@/components/feedback/components/Score.vue";
import DynamicScore from "@/components/feedback/components/DynamicScore.vue";
import clientCache from "@/utils/cacheUtils";
import DataExportDialog from "@/components/DataExportDialog.vue";
import MoveAndChatExportDialog from "@/components/MoveAndChatExportDialog.vue";
import { generateRandomString } from "@/utils";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import {IUser} from "@/schemas/IUser";
import PieChart from "@/components/charts/PieChart.vue";

@Component({
  name: "SequencePage",
  components: {
    PieChart,
    MoveAndChatExportDialog,
    DataExportDialog,
    DynamicScore,
    Score,
    UserSessionsList,
  },

  computed: {
    ...mapGetters("user", ["canEnterAdmin", "isResearcher", "isAdministrator"]),
    ...mapState("sequence", ["sequence"]),
  },

  methods: {
    ...mapActions("setting", ["fetchSettingScores", "fetchSettingSessions", "fetchSettingFeedbacks", "fetchSettingScoreMax"]),
    ...mapActions("session", ["getSessionWordNumber"]),
    ...mapMutations("sequence", {
      setSequence: "SET_SEQUENCE",
    }),
    watchSequence() {

      this.$watch('sequence', () => {
        this.sequence.settings.forEach((setting) => {
          this.groups_by_id[setting.id] = []

          this.onPanelChange(setting.id)

          if( window.localStorage )
          {
            if( !localStorage.getItem('firstLoad') ||  localStorage['firstLoad'] == false)
            {
              localStorage['firstLoad'] = true;
              window.location.reload();
            }

          }
        })
      });

    },
  },
})
export default class SequencePage extends Vue {
  @Prop({ required: false, type: String })
  readonly id: string | undefined;
  @Prop({ required: false, type: String })
  readonly code: string | undefined;


  private userList = []
  private finished = null;
  private onGoing = null;

  // groups: {
  //   users: string[];
  //   status: string;
  //   session_id: string;
  //   started_time: string;
  //   fullyLoaded: boolean;
  // }[] = [];

  nbUsers: number;
  private canEnterAdmin: boolean | undefined;
  private sequenceCode = "";

  end_loading=false;

  groups_by_id : {activity: string; groups:{
      users: string[];
      status: string;
      session_id: string;
      started_time: string;
    }[];
  }[] = [];


  tab = null;
  tabTerminé = null;
  tabs = ["status", "scores"];
  tabsTerminé = ["status", "scores", "feedbacks"];
  scores: {
    session_id: string;
    group_score: number;
    individual_scores: {
      user: string;
      score: number;
    }[];
  }[] = [];
  enabledSettings: string[] = [];


  data(): any {
    return {
      nbUser: "",
      nbUsers: "",
      selectedList: [],
      selectedListJSON: [],
      dialogCancel: false,
      dialog: false,
      // userList: [],
      participation : {},
      participationUser : {},
      fullyLoaded: {},
      groups:[],
      deleteMode:false,
      waiter:false,
      overlay:false,
      maxScore:112,
      panel:[0]
    }
  }



  created(): void {
    this.watchSequence()

    if (this.id) {
      this.$store.dispatch("sequence/fetchFullById", {
        id: this.id,
        next: (sequence: ISequence) => {
          this.setSequence(sequence);
        },
      });
    }
    else if (this.sequence.code) {
      this.$store.dispatch("sequence/fetchFullByCode", {
        code: this.sequence.code,
        next: (sequence: ISequence) => {
          this.setSequence(sequence);
        },
      });
    }
    if (this.id) {
      this.$store.dispatch("sequence/fetchUserSequenceById", {
        id: this.id,
        next: (userList: ISequence) => {
          this.userList = userList.sort((a,b) => parseInt(a.username.slice(5)) - parseInt(b.username.slice(5)))
          if (userList.length <2){
            this.nbUsers="Élève enregistré : "+userList.length
          }
          else{
            this.nbUsers="Élèves enregistrés : "+userList.length
          }
        },
      });
    }

    // this.getSessionWordNumber({
    //   sessionId: this.$route.params.sessionId,
    //   next: (participation) => {
    //     this.participation = participation.map((part) => part.participation);
    //     this.fullyLoaded = true;
    //   },
    // });

  }

  mounted(): void {

    if (this.$route.params.toRefresh){
      setTimeout(function() {
        window.location.reload();
      }, 2000);
    }
  }





  get numberErrors(): string[] {
    let errors: string[] = [];
    if (typeof this.nbUser != "number" || this.nbUser.length == 0){
      errors.push("Veuillez entrer un nombre")
    }

    // !this.$v.email.email && errors.push(UFFormValidationMessage.invalidEmail);
    // !this.$v.email.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  selectUser(code) : void{
    if (this.selectedList.includes(code)) {
      this.selectedList = this.selectedList.filter(
        (item) => item !== code
      );
      this.selectedListJSON = this.selectedListJSON.filter(
        (item) => item !== code
      );
    } else {
      this.selectedList.push(code);
      this.selectedListJSON.push({code: code});
    }
  }

  exportUserSequence(): void{
    const data = this.userList;
    const content = data.map(e => [e.username, e.username]);
    const csvContent = "Nom,Mot de Passe\n" + content.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(blob));
    link.setAttribute("download", `liste_user_sequence${this.sequence.code}.csv`);
    link.click();
  }

  activateDeleteMode(){
    this.deleteMode=!this.deleteMode
  }

  deleteUserSequence(userCode) : void{
    this.dialog=false
    this.userList = this.userList.filter((item) => {
      return item.code != userCode
    });
    this.selectedListJSON.push({code: userCode});
    const pl = {sequence_code: this.sequence.code, ul: this.selectedList }
    this.$store.dispatch("user/deleteUserSequence",{
      sequence_code: this.sequence.code,
      users: this.selectedListJSON,
      next: (userList2: ISequence) => {

        if (this.userList.length <2){
          this.nbUsers="Utilisateur enregistré : "+this.userList.length
        }
        else{
          this.nbUsers="Utilisateurs enregistrés : "+this.userList.length
        }
      },
    })
    this.$store.dispatch("sequence/setRequestingUser", {
      code: this.code,
      usersList: this.userList,
    });
    this.selectedListJSON=[]
  }


  ajouterUtilisateur() : void{
    this.waiter = true;
    let toCreate=this.nbUser
    this.nbUser=0
    this.$store.dispatch("user/createNUsers",
      {qte: toCreate,
        sequence_code: this.sequence.code,
        next: (userList: ISequence) =>
        {

          this.$store.dispatch("sequence/fetchUserSequenceById", {
            id: this.id,
            next: (userList: ISequence) => {
              this.userList = userList.sort((a,b) => a.username.slice(this.sequence.code.length) - b.username.slice(this.sequence.code.length))
              if (userList.length <2){
                this.nbUsers="Utilisateur enregistré : "+userList.length
              }
              else{
                this.nbUsers="Utilisateurs enregistrés : "+userList.length
              }
              this.waiter = false;
            },
          });
        }
      })
  }



  isFinished(groups:[]): boolean{
    let res=true
    groups=Object.values(groups)
    if(!groups){
      return false
    }
    if (groups.length==0){
      return false
    }
    for (let i=0 ; i<groups.length ; i++){
      if(groups[i].status!= "finished"){
        res=false
      }
    }
    return res
  }


  get allEnabled(): boolean {
    return this.sequence
      ? this.sequence.settings.every((setting) => !!setting.opened_at)
      : false;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  /**
   * Return user friendly status
   * @param status
   * @returns string
   */
  getUFStatus(status: string): string {
    return UFSessionStateEnum[status];
  }
  /**
   * Return status corresponding color
   * @param status
   * @returns string
   */
  getUFStatusColor(status: string): string {
    return UFSessionStateColorEnum[status];
  }

  updateSequence(sequenceCode: string): void {
    this.$store.dispatch("sequence/updateSequence", {
      sequence: { ...this.sequence, code: sequenceCode },
      next: (updated: ISequence) =>
        this.$store.commit("sequence/SET_SEQUENCE", updated),
    });
  }

  onPanelChange(settingId: string): void {
    this.tabTerminé = "status"
    this.fetchSettingSessions({
      settingId,
      next: (
        groups: {
          users: string[];
          status: string;
          session_id: string;
          started_time: string;
        }[]
      ) => {
        if(this.groups_by_id[settingId].length==0){
          this.groups_by_id[settingId] = {...groups};
        }
        this.groups = groups;
        if(this.groups!=null){
          for(let i=0; i< this.groups.length ; i++){
            this.groups[i].fullyLoaded=false
            this.pieChartFeed(this.groups[i].session_id, i)
          }
        }
      },
    });
    this.fetchSettingScoreMax({
      settingId,
      next: (
        scoreMax
      ) => {
        let somme=0
        for(let i=1; i<= scoreMax[0].nba ; i++){
          somme = somme + Math.abs(i - (scoreMax[0].nba -i + 1));
        }

        this.maxScore = somme;
      },
    });
  }

  generateCode(): void {
    this.sequenceCode = generateRandomString(5);
  }

  pieChartFeed(sessionId, i): void{

    if(this.groups!=null){
      this.getSessionWordNumber({
        sessionId: sessionId,
        next: (participation) => {
          if(this.groups!=null) {
            const participationNoDuplicate = participation.filter((obj1, i, arr) =>
              arr.findIndex(obj2 => (obj2.username === obj1.username)) === i
            );
            if(this.groups[i]!=null){
              this.groups[i].participation = participationNoDuplicate.map((part) => part.participation);
              this.groups[i].participationUser = participationNoDuplicate.map((part) => part.username);
              this.groups[i].fullyLoaded = true
            }
          }
        },
      });}
  }

  onTabChange(key: string): void {
    let settingId = key.split("_")[0];
    if (key.includes("scores")) {
      this.fetchSettingScores({
        settingId,
        next: (
          scores: {
            session_id: string;
            group_score: number;
            individual_scores: { user: string; score: number }[];
          }[]
        ) => {
          this.scores = scores;
        },
      });




    }

    if (key.includes("feedbacks")) {
      for(let i=0; i< this.groups.length ; i++){
        this.groups[i].fullyLoaded=false
        this.pieChartFeed(this.groups[i].session_id, i)
        this.groups[i].fullyLoaded=true
      }
    }
  }



  get notOpenYet(): boolean {
    return !this.sequence.code || !this.sequence.is_open;
  }

  openActivity(id: string): void {
    this.enabledSettings.push(id);
  }

  disableActivity(id: string): void {
    this.enabledSettings = this.enabledSettings.filter(
      (setting_id) => setting_id !== id
    );
  }

  onCheckChange(isChecked: boolean, setting_id: string): void {
    isChecked
      ? this.openActivity(setting_id)
      : this.disableActivity(setting_id);
  }

  openSelectedSettings(): void {
    clientCache.cache(
      `currentOpenedSettings:${this.sequence.id}`,
      JSON.stringify(this.enabledSettings)
    );
    this.end_loading=false
    this.$router.push({
      name: "sequenceRequests",
      params: {
        id: this.sequence.id,
        code: this.sequence.code,
      },
    });
  }

  get displaySettingOpener(): boolean {
    return (
      !!this.sequence.code &&
      this.canEnterAdmin &&
      this.$route.name === "sequence" &&
      !this.allEnabled
    );
  };
}
