
import { mapActions, mapMutations, mapState } from "vuex";
import { Prop, Vue, Component } from "vue-property-decorator";
import { ActivityType, SessionState } from "@/schemas/Enums";
import { UFliterals } from "@/utils/literals";

/**
 * UserSessionsList Component
 * Display the user's session list
 */
@Component({
  name: "UserSessionsList",
  computed: {
    ...mapState("user", ["sessions"]),
    ...mapState(["isAuthenticated"]),
  },
  methods: {
    ...mapActions("user", ["fetchUserSessions", "fetchUserSequencesSessions"]),
    ...mapMutations({ setSnack: "SET_SNACK" }),
  },
})
export default class UserSessionsList extends Vue {
  @Prop({ required: false, type: String })
  readonly sequenceId: string | undefined;

  fetchUserSessions!: () => any;
  fetchUserSequencesSessions!: (payload: { sequenceId: string }) => any;

  mounted(): void {
    if (this.sequenceId) {
      this.fetchUserSequencesSessions({ sequenceId: this.sequenceId });
    } else {
      this.fetchUserSessions();
    }
  }
  /**
   * Redirect route to the sessionPage identified by sessionId
   * @param session
   */
  redirectToSession(session: {
    session_id: string;
    status: SessionState;
    type: ActivityType;
  }): void {
    if (session.type == ActivityType.Assignment) {
      this.$router.push({
        name: "assignment",
        params: {
          sessionId: session.session_id,
        },
      });
      return;
    }
    if (session.status === SessionState.RunningGroup) {
      this.$router.push({
        name: "groupSession",
        params: {
          sessionId: session.session_id,
        },
      });
      return;
    }
    if (session.status === SessionState.RunningSingle) {
      this.$router.push({
        name: "singleSession",
        params: {
          sessionId: session.session_id,
        },
      });
      return;
    }
    this.$router.push({
      name: "overview",
      params: {
        sessionId: session.session_id,
      },
    });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
